<template>
  <div class="boxes">
    <div class="section_container">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-lg-4 mb-5 pb-5 box_col"
            v-for="(category, c) of categories"
            :key="c"
          >
            <div class="box">
              <div class="box_image">
                <img :src="category.thumbnail_url">
              </div>
              <div class="box_title trans_200">
                <router-link
                  class="trans_200"
                  :to="`/categories/${getCategorySlug(category.canonical)}`"
                >
                  {{ category.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getCategorySlug(canonical) {
      const parts = canonical.split('/');
      return parts[parts.length - 1];
    },
  },
};
</script>


<style lang="scss" scoped>
@import 'src/scss/partials/home-categories.scss';
</style>
